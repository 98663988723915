@import "../../../styles/base/abstracts";

.hbs-cta.hbs-cta--themed {
  @include padding($grid-padding-mobile x);

  @include mq($bp-tablet) {
    @include padding($grid-padding x);
  }

  @include mq(max, $bp-tablet) {
    @include margin(x #{$grid-padding-mobile * -2});
  }

  @include mq($bp-tablet, $bp-desktop) {
    @include margin(x #{$grid-padding * -2});
  }
}

.hbs-cta__wrapper {
  @include site-max-width;
  background-color: inherit;
  justify-content: space-between;
  align-items: flex-end;

  .hbs-cta--themed & {
    @include grid-parent;

    &--media-left {
      flex-direction: row-reverse;
    }
  }

  .hbs-cta--flat & {
    @include grid-parent($offset: true);

    &--media-left {
      flex-direction: row-reverse;
    }
  }
}

.hbs-cta__content {
  @include grid-child;
  display: flex;
  flex-direction: column;
  order: 1;

  @include mq($bp-tablet) {
    width: $col-12;
  }

  @include mq($bp-desktop) {
    margin-left: $col-4;
    width: $col-8;
  }

  @include mq($bp-desktop-xl) {
    width: $col-6;
  }
}

.hbs-cta .hbs-cta__title,
.hbs-cta .hbs-cta__subtitle {
  @include h3($font-weight: $fw-reg);
  max-width: 16em;
}

.hbs-cta__subtitle {
  color: $c-text-lighter;
}

// CTAs
.hbs-cta__cta-wrapper {
  padding-top: $spacing-xl;

  .hbs-cta-link--primary-button,
  .hbs-cta-link--secondary-button {
    margin-right: $spacing-xl;
    margin-top: $spacing-md;
  }

  .hbs-cta-link--link {
    @include padding($spacing-sm x);
  }
}

// Media
.hbs-cta__media {
  align-items: baseline;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: $grid-padding-mobile * 2;
  width: 100%;

  .hbs-media-asset {
    @include grid-child;
  }

  @include mq($bp-tablet) {
    order: 1;
    padding-bottom: 0;
  }

  @include mq($bp-desktop) {
    margin-top: 0;
  }
}

// Media Count
.hbs-cta--asset-count-1,
.hbs-cta--asset-count-2 {
  .hbs-cta__content {
    margin-left: 0;
  }
}

.hbs-cta--asset-count-1 {
  .hbs-cta__content {
    @include mq($bp-tablet) {
      width: $col-8;
    }

    @include mq($bp-desktop) {
      margin-left: 0;
      width: $col-8;
    }
  }

  .hbs-cta__media {
    @include mq($bp-tablet) {
      width: $col-8;
    }
  }
}

.hbs-cta--asset-count-2 {
  .hbs-cta__media {
    .hbs-media-asset {
      width: $col-8;
    }
  }

  @include mq($bp-tablet) {
    .hbs-cta__media,
    .hbs-cta__content {
      width: 50%;
    }
  }
}

// Alignments
.hbs-global-align-center,
.hbs-global-align-wide,
.hbs-global-align-full {
  .hbs-cta {
    @include mq(max, $bp-tablet) {
      @include margin(x 0);
    }
  }

  .hbs-cta__content,
  .hbs-cta__media {
    @include margin($grid-padding-mobile x);

    @include mq($bp-tablet) {
      @include margin($grid-padding x);
    }
  }

  @include mq($bp-tablet) {
    .hbs-cta__content {
      width: 100%;
    }

    .hbs-cta--asset-count-1,
    .hbs-cta--asset-count-2 {
      .hbs-cta__content,
      .hbs-cta__media {
        width: 50%;
      }
    }
  }

  @include mq($bp-desktop) {
    .hbs-cta__content {
      margin-left: 0;
      width: nested-cols(6, 9);
    }

    .hbs-cta__media {
      margin-left: auto;
      width: nested-cols(4, 9);
    }
  }
}

.hbs-global-align-center {
  @include mq($bp-desktop) {
    .hbs-cta--asset-count-1,
    .hbs-cta--asset-count-2 {
      .hbs-cta__content {
        order: 2;
        width: 100%;
      }
    }

    .hbs-cta__content {
      width: 100%;
    }

    .hbs-cta--asset-count-2 {
      .hbs-cta__media {
        width: nested-cols(6, 9);
      }
    }
  }
}

.hbs-global-align-wide {
  @include mq($bp-desktop) {
    .hbs-cta__content {
      order: 1;
      width: 100%;
    }

    .hbs-cta--asset-count-1,
    .hbs-cta--asset-count-2 {
      .hbs-cta__content,
      .hbs-cta__media {
        width: 50%;
      }
    }
  }
}

.hbs-global-align-full {
  @include mq($bp-desktop) {
    .hbs-cta--asset-count-1,
    .hbs-cta--asset-count-2 {
      .hbs-cta__content {
        margin-left: 0;
        width: 50%;
      }
    }
  }
}
